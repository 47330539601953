<script setup lang="ts">
const props = defineProps<{
    categoryIds: string[];
    config: any;
}>();

const {t} = useI18n()
const {algoliaGetObjects, getCategoryIndexName} = useAlgolia();

const imgSize = ref<Number>(125);
const subcategories = ref([]);
const sliderElem = ref(null);
const sliderNavPrev = ref<HTMLElement|null>(null);
const sliderNavNext = ref<HTMLElement|null>(null);

if (props.categoryIds.length > 0) {
    const subCats = await algoliaGetObjects(null, getCategoryIndexName(), props.categoryIds);
    subcategories.value = subCats.value?.results;
    subcategories.value = subcategories.value.filter(n => n);
}

function getRelativeUrl(absoluteUrl) {
    const url = new URL(absoluteUrl);
    return url.pathname;
}

const title = props.config.title?.value ?? t('listing.popularCategories')
const updateImgSize = (items: number) => imgSize.value = items < 4 ? 125 : 185;

const swiper = ref(null);

const nextSlide = () => swiper.value.slideNext();
const prevSlide = () => swiper.value.slidePrev();

const swiperProgress = (swiper, progress) => {
    const toggleClass = 'opacity-0';
    if (progress === 1) {
        sliderNavNext.value?.classList.add(toggleClass);
    } else {
        sliderNavNext.value?.classList.remove(toggleClass);
    }

    if (progress === 0) {
        sliderNavPrev.value?.classList.add(toggleClass);
    } else {
        sliderNavPrev.value?.classList.remove(toggleClass);
    }

};

</script>
<template>
    <div class="carousel-element container pt-6">
        <div v-if="subcategories.length > 0" class="pt-2">
            <h4 class="ml-5 mb-5 font-semibold text-[22px]">
                {{ title }}
            </h4>
            <div class="relative">
                <Swiper
                    ref="sliderElem"
                    :breakpoints="props.config.breakpoints"
                    @breakpoint="(swiper, breakpointParams) => updateImgSize(breakpointParams.slidesPerView)"
                    @swiper="(swiperInstance) => swiper = swiperInstance"
                    @progress="swiperProgress"
                    :slides-per-view="2"
                    :css-mode="true"
                    :mousewheel="{forceToAxis: true, releaseOnEdges: true}"
                    :speed="100"
                    :grab-cursor="true"
                >
                    <SwiperSlide v-for="subcategory in subcategories" :key="subcategory.objectID">
                        <a :href="getRelativeUrl(subcategory.url)" class="flex flex-col items-center">
                            <NuxtImg
                                v-if="subcategory.media.url"
                                :src="subcategory.media.url"
                                :alt="subcategory.name"
                                :width="imgSize"
                                :height="imgSize"
                                class="img-fluid"
                                loading="lazy"
                            />
                            <p class="text-center text-[19px] font-semibold">{{subcategory.name}}</p>
                        </a>
                    </SwiperSlide>
                </Swiper>
                <div ref="sliderNavPrev" class="slider-nav-prev" @click="prevSlide"><NuxtImg src="/arrow-left-bold.svg" width="7" height="13"/></div>
                <div ref="sliderNavNext" class="slider-nav-next" @click="nextSlide"><NuxtImg src="/arrow-left-bold.svg" width="7" height="13" class="rotate-180"/></div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.slider-nav-prev, .slider-nav-next {
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;

    box-shadow: 0px 3px 6px #00000029;
    background: #fff;

    position: absolute;
    top: calc(50% - 20px);
    z-index: 1;

    transition: opacity 0.2s;

}

.slider-nav-prev {
    left: 10px;
    padding: 10px 16px;
}

.slider-nav-next {
    right: 10px;
    padding: 10px 18px;
}
</style>
